<template>
  <div id="flightlogDetail" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-36">
          Flight Log
          <span v-if="DataFromDB.f_base_approve == true" class="T-size-14" style="color: #00ba48">Approved</span>
        </div>
      </div>
    </div>
    <div id="flightDetail" class="wrap-Main">
      <div class="box-S2">
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S2">
                <v-text-field label="Ref No." disabled></v-text-field>
              </div>
              <div class="box-S2">
                <v-text-field label="Flight No." v-model="DataFromDB.f_flight_no" disabled></v-text-field>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S2">
                <v-combobox class="hideMessage" label="Customer" v-model="DataFromDB.o_name" disabled></v-combobox>
              </div>
              <div class="box-S2">
                <v-combobox class="hideMessage" label="Type Of Flight" v-model="DataFromDB.f_type_of_flight"
                  disabled></v-combobox>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S4">
                <v-combobox class="hideMessage" label="FH Basis" v-model="DataFromDB.FH_basis" disabled></v-combobox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S2">
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S4">
                <v-combobox label="Pilot" v-model="DataFromDB.f_pilot_name" disabled></v-combobox>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S4">
                <v-combobox class="hideMessage" label="Co-Pilot" v-model="DataFromDB.f_copilot_name"
                  disabled></v-combobox>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S4">
                <v-combobox class="hideMessage" label="Aircraft" v-model="DataFromDB.ac_name" disabled></v-combobox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <!-- <div id="flightReport" class="wrap-Main">
      <div class="box-S2">
        <div class="box-S4">
          <div class="N-Page T-size-24">Manifest</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail">
            <v-btn class="theme-btn-even btn-report center Bsize190">
              <span class="I-report"></span>
              <span class="T-size-18">Manifest</span>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="box-S2">
        <div class="box-S4">
          <div class="N-Page T-size-24">Seat Layout</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail">
            <v-btn
              class="theme-btn-even btn-report center Bsize190"
              :to="{
                name: 'SeatLayout',
                params: { mode: 'FlightLogDetail', idflight: idflight },
              }"
              style="padding-top: 6.5px"
            >
              <span class="I-report"></span>
              <span class="T-size-18">Seat Layout</span>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="line"></div>
    </div> -->

    <div id="destiantionDetail" class="wrap-Main">
      <div class="box-S2">
        <div class="box-S4">
          <div class="N-Page T-size-24">Departure</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S4">
                <v-combobox class="hideMessage" label="Departure Airport" v-model="DataFromDB.departairport"
                  disabled></v-combobox>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S2">
                <v-text-field label="STD" v-model="DataFromDB.ap_depart_date" disabled></v-text-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S2">
        <div class="box-S4">
          <div class="N-Page T-size-24">Arrival</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S4">
                <v-combobox class="hideMessage" label="Arrival Airport" v-model="DataFromDB.deestairport"
                  disabled></v-combobox>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S2">
                <v-text-field label="STA" v-model="DataFromDB.ap_dest_date" disabled></v-text-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>
    </div>

    <div id="destinationLeg" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">Flight Destination</div>
      </div>
      <div class="box-S4">
        <div class="warp-destiantionShow">
          <div class="box-S1">
            <!-- Leg เริ่มต้น -->
            <div class="b-destiantionShow start">
              <div class="B-carddetail">
                <div class="b-content">
                  <i class="I-departure"></i>
                  <div class="t-name T-size-20 SemiBold">
                    {{ DataFromDB.departairport }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="box-S1" v-for="item in 6" :key="item"> -->
          <div class="box-S1" v-for="item in ArrlistAddLegs" :key="item">
            <!-- Loop ชุดนี้ -->
            <div class="b-destiantionShow">
              <div class="B-carddetail">
                <div class="b-content">

                  <div class="t-name T-size-20 SemiBold">
                    {{ item.ap_name }}
                  </div>
                  <div class="tag" v-show="item.activity1 != null">
                    {{ item.activity1 }}
                  </div>
                  <div class="tag" v-show="item.activity2 != null">
                    {{ item.activity2 }}
                  </div>
                  <div class="tag" v-show="item.activity3 != null">
                    {{ item.activity3 }}
                  </div>
                  <div class="tag" v-show="item.activity4 != null">
                    {{ item.activity4 }}
                  </div>
                  <div class="numderPassenger">
                    <div class="t-up T-size-24">
                      <span>{{ item.l_ob | setzoro }}</span>
                      <i class="I-down"></i>
                    </div>
                    <div class="t-down T-size-24">
                      <i class="I-up"></i>
                      <span>{{ item.l_ib | setzoro }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box-S1">
            <!-- Leg จบ -->
            <div class="b-destiantionShow end">
              <div class="B-carddetail">
                <div class="b-content">
                  <i class="I-arrival"></i>
                  <div class="t-name T-size-20 SemiBold">
                    {{ DataFromDB.deestairport }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>
    </div>

    <div id="reasonDelay" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">Skytrac</div>
      </div>
      <div class="box-S4">
        <Skytrac ref="Skytrac" :AircraftName="DataFromDB.ac_name" :StartDate="DataFromDB.ap_depart_date"
          :EndDate="DataFromDB.ap_dest_date" :FlightID="$route.params.id" :DepartFrom="DataFromDB.departairport">
        </Skytrac>
      </div>

      <div class="line marginTop"></div>

      <div class="box-S4">
        <div class="N-Page T-size-24">Reason for Delay</div>
      </div>
      <div class="box-S4">
        <div class="B-carddetail B-formDelay">
          <div class="box-S4 noPadding flex-between-center">
            <div class="box-S1">
              <v-text-field label="Delay Time (minutes)" v-model="delayTime" hide-details :readonly="
                readonly_shore ||
                readonly_eng ||
                readonly_pilot ||
                readonly_base ||
                readonly_financial ||
                readonly_management
              "></v-text-field>
            </div>
            <div class="box-S1">
              <v-select class="hideMessage" label="Delay Cause" :items="ListDelayCause" v-model="delaycause"
                item-text="d_name" item-value="d_id" return-object hide-details :readonly="
                  readonly_shore ||
                  readonly_eng ||
                  readonly_pilot ||
                  readonly_base ||
                  readonly_financial ||
                  readonly_management
                "></v-select>
            </div>
            <div class="box-S1">
              <v-select class="hideMessage" label="Delay Code" :items="ListDelayCode" v-model="delaycode"
                item-text="dc_code_name" item-value="dc_id" return-object hide-details :readonly="
                  readonly_shore ||
                  readonly_eng ||
                  readonly_pilot ||
                  readonly_base ||
                  readonly_financial ||
                  readonly_management
                "></v-select>
            </div>
            <div class="box-S1">
              <v-text-field label="Reason for Delay" v-model="delayremark" hide-details :readonly="
                readonly_shore ||
                readonly_eng ||
                readonly_pilot ||
                readonly_base ||
                readonly_financial ||
                readonly_management
              "></v-text-field>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S4">
        <v-expansion-panels expandaccordion expand class="b-accod">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="panal-title T-size-24">Delay Cause & Delay Code</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="box-S4">
                <div class="N-Page T-size-18">Note(1): Delay Cause</div>
              </div>
              <div class="B-delayCause B-carddetail">
                <div class="b-Cause"><span>[1]</span> UOA</div>
                <div class="b-Cause"><span>[2]</span> CPOC</div>
                <div class="b-Cause"><span>[3]</span> Other</div>
              </div>
              <div class="box-S4">
                <div class="N-Page T-size-18">Note(2): Delay Code</div>
              </div>
              <div class="B-delayCode B-carddetail">
                <div class="b-Code"><span>[1]</span> ATC</div>
                <div class="b-Code"><span>[2]</span> Weather</div>
                <div class="b-Code">
                  <span>[3]</span> Traffic at airport/ traffic offshore
                </div>
                <div class="b-Code"><span>[4]</span> Late manifest</div>
                <div class="b-Code">
                  <span>[5]</span> Communication / Internet failure
                </div>
                <div class="b-Code"><span>[6]</span> Fuelling</div>
                <div class="b-Code">
                  <span>[7]</span> Technical Problem and consequence from
                  technical problem
                </div>
                <div class="b-Code"><span>[8]</span> Flight Planning</div>
                <div class="b-Code">
                  <span>[9]</span> Late incoming of aircraft
                </div>
                <div class="b-Code"><span>[10]</span> Late Crew</div>
                <div class="b-Code"><span>[11]</span> Late PSR</div>
                <div class="b-Code">
                  <span>[12]</span> Late boarding due to passenger
                </div>
                <div class="b-Code"><span>[13]</span> Other</div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="line marginTop"></div>
    </div>
    <div id="LegDetail" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">Flight Leg</div>
      </div>
      <div class="b-LegLog box-S4 B-carddetail" v-for="(item, indexleg) in ArrLegs" :key="item.fld_id">
        <div class="b-nameLocation flex-between-center">
          <div class="box-S2 noPadding">
            <div class="b-neme T-size-20">Leg {{ indexleg + 1 }}</div>
          </div>
          <div class="b-btn" v-if="userdata.r_id == 4 || userdata.r_id == 21">
            <v-btn class="theme-btn-even btn-delete" @click="DeleteLeg(item, indexleg)">
              <span class="I-bin"></span>
              <span>Delete Leg</span>
            </v-btn>
          </div>
        </div>
        <div class="b-inputData box-S4 noPadding">
          <div class="box-S4 noPadding flex-between-center">
            <div class="box-S2">
              <div class="b-neme T-size-20">Flight Data</div>
            </div>
            <div class="box-S2 noPadding flex-between">
              <div class="box-S2 noPadding">
                <v-select class="hideMessage" label="Start Location" :items="ListAircraft" v-model="item.start_location"
                  item-text="ap_name" item-value="ap_id" return-object :readonly="
                    readonly_shore ||
                    readonly_eng ||
                    readonly_pilot ||
                    readonly_base ||
                    readonly_financial ||
                    readonly_management
                  "></v-select>
              </div>
              <div class="box-S2 noPadding">
                <v-select class="hideMessage" label="End Location" :items="ListAircraft" v-model="item.end_location"
                  item-text="ap_name" item-value="ap_id" return-object :readonly="
                    readonly_shore ||
                    readonly_eng ||
                    readonly_pilot ||
                    readonly_base ||
                    readonly_financial ||
                    readonly_management
                  "></v-select>
              </div>
            </div>
          </div>
          <div class="box-S4 flex-between noPadding marginTop">
            <div class="box-S1 noPadding">
              <div class="b-row">
                <v-text-field class="hideMessage" type="time" label="Start Engine" v-model="item.start_engine"
                  :readonly="
                    readonly_shore ||
                    readonly_eng ||
                    readonly_pilot ||
                    readonly_base ||
                    readonly_financial ||
                    readonly_management
                  "></v-text-field>
                <v-row align="center" style="padding-left: 10px">
                  <v-checkbox v-model="item.has_texi_time" hide-details class="shrink mr-2 mt-0" :readonly="
                    readonly_shore ||
                    readonly_eng ||
                    readonly_pilot ||
                    readonly_base ||
                    readonly_financial ||
                    readonly_management
                  "></v-checkbox>
                  <p v-if="!item.has_texi_time">Taxi Time</p>
                  <v-text-field v-if="item.has_texi_time" style="margin-left: -10px; margin-right: 10px"
                    class="hideMessage" type="time" label="Taxi Time" v-model="item.texi_time" :readonly="
                      readonly_shore ||
                      readonly_eng ||
                      readonly_pilot ||
                      readonly_base ||
                      readonly_financial ||
                      readonly_management
                    "></v-text-field>
                </v-row>
                <v-text-field class="hideMessage" type="time" label="Up Time" v-model="item.up_time" :readonly="
                  readonly_shore ||
                  readonly_eng ||
                  readonly_pilot ||
                  readonly_base ||
                  readonly_financial ||
                  readonly_management
                "></v-text-field>
                <!-- ปิด start_fuel 20220323 -->
                <!-- <v-text-field
                  class="hideMessage"
                  label="Start Fuel (Kg)"
                  v-model="item.start_fuel"
                  :readonly="
                    readonly_shore ||
                    readonly_eng ||
                    readonly_pilot ||
                    readonly_base ||
                    readonly_financial
                  "
                ></v-text-field> -->
              </div>
            </div>
            <div class="box-S2 inputWaypoint">
              <div class="box-S4 b-inputWaypoint" v-for="(itemArrWaypoints, index) in item.ArrWaypoints"
                :key="itemArrWaypoints.w_id">
                <div class="box-S4 noPadding flex-between">
                  <div class="t-name">Way Point {{ index + 1 }}</div>
                  <v-btn fab class="primary btn-delete" @click="DeleteWayPoint(itemArrWaypoints, indexleg)"
                    v-if="userdata.r_id == 4 || userdata.r_id == 21">
                    <span class="I-bin"></span>
                  </v-btn>
                </div>
                <div class="box-S4 noPadding flex-between">
                  <div class="box-S2 noPadding">
                    <v-text-field class="hideMessage" type="time" label="Time" v-model="itemArrWaypoints.time"
                      :readonly="
                        readonly_shore ||
                        readonly_eng ||
                        readonly_pilot ||
                        readonly_base ||
                        readonly_financial ||
                        readonly_management
                      "></v-text-field>
                  </div>
                  <div class="box-S2 noPadding">
                    <v-text-field class="hideMessage" label="Fuel" v-model="itemArrWaypoints.fuel" :readonly="
                      readonly_shore ||
                      readonly_eng ||
                      readonly_pilot ||
                      readonly_base ||
                      readonly_financial ||
                      readonly_management
                    "></v-text-field>
                  </div>
                </div>
              </div>
              <div class="box-S4 noPadding" v-if="userdata.r_id == 4 || userdata.r_id == 21">
                <v-btn class="theme-btn-even MGB10 w100" @click="AddWayPoint(indexleg)">
                  <span class="I-create"></span>
                  <span>Add Way Point</span>
                </v-btn>
              </div>
            </div>
            <div class="box-S1 noPadding">
              <div class="b-row">
                <v-text-field class="hideMessage" type="time" label="Down Time" v-model="item.down_time" :readonly="
                  readonly_shore ||
                  readonly_eng ||
                  readonly_pilot ||
                  readonly_base ||
                  readonly_financial ||
                  readonly_management
                "></v-text-field>
                <!-- <v-checkbox
                  v-model="item.has_stop_engine"
                  label="Has Stop Engine"
                ></v-checkbox>
                <v-text-field
                  v-if="item.has_stop_engine"
                  class="hideMessage"
                  type="time"
                  label="Stop Engine"
                  v-model="item.stop_engine"
                  :readonly="
                    readonly_shore ||
                    readonly_eng ||
                    readonly_pilot ||
                    readonly_base ||
                    readonly_financial
                  "
                  @change="CalFlightTime(indexleg)"
                ></v-text-field> -->

                <v-row align="center" style="padding-left: 10px">
                  <v-checkbox v-model="item.has_stop_engine" hide-details class="shrink mr-2 mt-0" :readonly="
                    readonly_shore ||
                    readonly_eng ||
                    readonly_pilot ||
                    readonly_base ||
                    readonly_financial ||
                    readonly_management
                  "></v-checkbox>
                  <p v-if="!item.has_stop_engine">Stop Engine</p>
                  <v-text-field v-if="item.has_stop_engine" style="margin-left: -10px; margin-right: 10px"
                    class="hideMessage" type="time" label="Stop Engine" v-model="item.stop_engine" :readonly="
                      readonly_shore ||
                      readonly_eng ||
                      readonly_pilot ||
                      readonly_base ||
                      readonly_financial ||
                      readonly_management
                    "></v-text-field>
                </v-row>

                <!-- ปิด end_fuel 20220323 -->
                <!-- <v-text-field
                  class="hideMessage"
                  label="End Fuel (Kg)"
                  v-model="item.end_fuel"
                  :readonly="
                    readonly_shore ||
                    readonly_eng ||
                    readonly_pilot ||
                    readonly_base ||
                    readonly_financial
                  "
                ></v-text-field> -->

                <v-select class="hideMessage" label="S/D" :items="itemShutdown" v-model="item.shutdown"
                  item-text="sd_name" item-value="sd_id" return-object :readonly="
                    readonly_shore ||
                    readonly_eng ||
                    readonly_pilot ||
                    readonly_base ||
                    readonly_financial ||
                    readonly_management
                  "></v-select>

                <v-text-field v-show="item.shutdown.sd_id > 1" class="hideMessage" label="Standby Time (minute)"
                  v-model="item.standby_time" :readonly="
                    readonly_shore ||
                    readonly_eng ||
                    readonly_pilot ||
                    readonly_base ||
                    readonly_financial ||
                    readonly_management
                  "></v-text-field>

                <div class="box-S4 noPadding" v-if="userdata.r_id == 4 || userdata.r_id == 21">
                  <v-btn class="theme-btn-even MGB10 w100" @click="CalFlightTime(indexleg)">
                    <v-icon>mdi mdi-calculator</v-icon>
                    <span>Calculator</span>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="box-S4 noPadding marginTop flex-column">
            <!-- Payload -->
            <div class="box-S4">
              <div class="b-neme T-size-20">Payload</div>
            </div>
            <div class="box-S4 noPadding flex-between-center">
              <div class="box-S1 noPadding">
                <v-text-field class="hideMessage" label="Available Payload" v-model="item.available_payload" :readonly="
                  readonly_shore ||
                  readonly_eng ||
                  readonly_pilot ||
                  readonly_base ||
                  readonly_financial ||
                  readonly_management
                "></v-text-field>
              </div>
              <div class="box-S1 noPadding">
                <v-text-field class="hideMessage" label="Actual Payload" v-model="item.actual_payload" :readonly="
                  readonly_shore ||
                  readonly_eng ||
                  readonly_pilot ||
                  readonly_base ||
                  readonly_financial ||
                  readonly_management
                "></v-text-field>
              </div>
              <div class="box-S1 noPadding">
                <v-text-field class="hideMessage" label="Acutal Pax" v-model="item.actual_pax" :readonly="
                  readonly_shore ||
                  readonly_eng ||
                  readonly_pilot ||
                  readonly_base ||
                  readonly_financial ||
                  readonly_management
                "></v-text-field>
              </div>
              <div class="box-S1 noPadding">
                <v-text-field class="hideMessage" label="Remark Details" v-model="item.delay_remark" :readonly="
                  readonly_shore ||
                  readonly_eng ||
                  readonly_pilot ||
                  readonly_base ||
                  readonly_financial ||
                  readonly_management
                "></v-text-field>
              </div>
            </div>

            <!-- Mileage & Fuel -->
            <div class="box-S4">
              <div class="b-neme T-size-20">Mileage & Fuel</div>
            </div>
            <div class="box-S4 noPadding flex-between-center">
              <div class="box-S2 noPadding">
                <v-text-field class="hideMessage" label="Mileage" v-model="item.mileage" :readonly="
                  readonly_shore ||
                  readonly_eng ||
                  readonly_pilot ||
                  readonly_base ||
                  readonly_financial ||
                  readonly_management
                "></v-text-field>
              </div>
              <div class="box-S2 noPadding">
                <v-text-field class="hideMessage" label="Fuel Consumption" v-model="item.fuel_consumption" :readonly="
                  readonly_shore ||
                  readonly_eng ||
                  readonly_pilot ||
                  readonly_base ||
                  readonly_financial ||
                  readonly_management
                "></v-text-field>
              </div>
            </div>
            <!-- Mileage & Fuel -->

            <div class="box-S4">
              <div class="N-Page T-size-24">Summary</div>
            </div>
            <div class="box-S4">
              <div class="warp-destiantionShow">
                <div class="box-S1">
                  <div class="B-carddetail">
                    <div class="b-detail">
                      <div class="b-icon">
                        <v-icon style="margin: 10px; font-size: 38pt">mdi-calculator</v-icon>
                      </div>
                      <div class="b-text">
                        <p>FH Basis</p>
                        <p class="T-size-20 SemiBold">
                          {{ DataFromDB.FH_basis }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box-S1">
                  <div class="B-carddetail">
                    <div class="b-detail">
                      <div class="b-icon">
                        <v-icon style="margin: 10px; font-size: 38pt">mdi-sort-clock-descending</v-icon>
                      </div>
                      <div class="b-text">
                        <p>Start</p>
                        <p class="T-size-20 SemiBold">
                          {{ item.flight_time_start }}
                          <span v-if="item.air_fee == 1" class="T-size-10">[Air Fee]</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box-S1">
                  <div class="B-carddetail">
                    <div class="b-detail">
                      <div class="b-icon">
                        <v-icon style="margin: 10px; font-size: 38pt">mdi-sort-clock-ascending</v-icon>
                      </div>
                      <div class="b-text">
                        <p>Stop</p>
                        <p class="T-size-20 SemiBold">
                          {{ item.flight_time_stop }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box-S1">
                  <div class="B-carddetail">
                    <div class="b-detail">
                      <div class="b-icon">
                        <v-icon style="margin: 10px; font-size: 38pt">mdi-helicopter</v-icon>
                      </div>
                      <div class="b-text">
                        <p>Revenue (Minutes)</p>
                        <p class="T-size-20 SemiBold">{{ item.flight_time }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="box-S1">
                  <div class="B-carddetail">
                    <div class="b-detail">
                      <div class="b-icon">
                        <v-icon style="margin: 10px; font-size: 38pt">mdi-engine</v-icon>
                      </div>
                      <div class="b-text">
                        <p>Start Engine (Minutes)</p>
                        <p class="T-size-20 SemiBold">
                          {{ item.start_engine_minute }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box-S1">
                  <div class="B-carddetail">
                    <div class="b-detail">
                      <div class="b-icon">
                        <v-icon style="margin: 10px; font-size: 38pt">mdi-road-variant</v-icon>
                      </div>
                      <div class="b-text">
                        <p>Taxi (Minutes)</p>
                        <p class="T-size-20 SemiBold">{{ item.taxi_minute }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box-S1">
                  <div class="B-carddetail">
                    <div class="b-detail">
                      <div class="b-icon">
                        <v-icon style="margin: 10px; font-size: 38pt">mdi-elevator-down</v-icon>
                      </div>
                      <div class="b-text">
                        <p>Down Time (Minutes)</p>
                        <p class="T-size-20 SemiBold">
                          {{ item.down_time_minute }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box-S1">
                  <div class="B-carddetail">
                    <div class="b-detail">
                      <div class="b-icon">
                        <v-icon style="margin: 10px; font-size: 38pt">mdi-fuel</v-icon>
                      </div>
                      <div class="b-text">
                        <p>Fuel Consumption</p>
                        <p class="T-size-20 SemiBold">
                          {{ item.fuel_consumption }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S4" v-if="userdata.r_id == 4 || userdata.r_id == 21">
        <v-btn class="theme-btn-even MGB10 w100" @click="AddLeg()">
          <span class="I-create"></span>
          <span>Add Leg</span>
        </v-btn>
      </div>
      <div class="line"></div>
    </div>
    <div id="partSave" class="wrap-Main">
      <div class="box-S4">
        <div class="b-btnSave" v-if="userdata.r_id == 4 || userdata.r_id == 21">
          <v-btn class="theme-btn-even btn-cancle Bsize150" @click="GotoFlightlog()">
            <span class="T-size-18">Cancel</span>
          </v-btn>
          <!-- <v-btn
            class="theme-btn-even btn-save Bsize150"
            @click="SaveFlightLog()"
          > -->
          <v-btn class="theme-btn-even btn-save Bsize150" @click="dialogSave = true">
            <span class="I-save"></span>
            <span class="T-size-18">Save</span>
          </v-btn>
        </div>
        <div class="b-btnSave" v-if="DataFromDB.f_base_approve != true && userdata.r_id == 13">
          <v-btn class="theme-btn-even btn-save Bsize150"
            v-if="DataFromDB.f_base_approve != true && userdata.r_id == 13" @click="dialogSaveApprove = true">
            <span class="T-size-18">Approve</span>
          </v-btn>
        </div>
        <!-- <div class="b-btnSave" v-if="DataFromDB.f_base_approve == true && userdata.r_id == 13">
          <v-btn visibled class="theme-btn-even btn-save Bsize150" v-if="DataFromDB.f_base_approve == true && userdata.r_id == 13">
            <span class="T-size-18">Approved</span>
          </v-btn>
        </div> -->
        <div class="b-btnDelete" v-show="mode == 'edit'">
          <v-btn class="theme-btn-even btn-delete Bsize150">
            <span class="T-size-18">delete booking</span>
          </v-btn>
        </div>
      </div>
    </div>
    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="GotoFlightlog()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>

    <!-- Add-->
    <v-dialog v-model="dialogSave" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog save">
            <div class="t-title">Confirm To Save ?</div>
            <div class="t-des">Are you sure to save this item?</div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogSave = false">
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-save Bsize100" text @click="SaveFlightLog()">
              <span class="T-size-18">Save</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Add -->
    <v-dialog v-model="dialogConfirm" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog confirm">
            <div class="t-title">Save Completed</div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-ok Bsize100" text @click="SaveCompleted()">
              <span class="T-size-18">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Add-->
    <v-dialog v-model="dialogSaveApprove" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog save">
            <div class="t-title">Confirm To Approve ?</div>
            <div class="t-des">Are you sure to approve this item?</div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogSaveApprove = false">
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-save Bsize100" text @click="Approve()">
              <span class="T-size-18">Yes</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Add -->
    <v-dialog v-model="dialogConfirmApprove" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog confirm">
            <div class="t-title">Approve Completed</div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-ok Bsize100" text @click="GotoFlightlog()">
              <span class="T-size-18">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import fillerdate from "../utils/filterdate";
import feathersClientUOA from "../plugins/feathers-client-uoa";
//Add
import {
  startOfDay,
  endOfDay,
  format,
  addHours,
  addMinutes,
  differenceInMinutes,
  getTime,
} from "date-fns";
import Skytrac from "../components/Skytrac";

export default {
  data: () => ({
    readonly_flight: false,
    readonly_pilot: false,
    readonly_eng: false,
    readonly_shore: false,
    readonly_finance: false,

    ArrlistAddLegs: [],
    ArrLegs: [],
    ArrWaypointsFromDB: [],
    ArrDataFlightLogFromDB: [],
    DataFromDB: null,
    DataDelayFromDB: null,
    ListAircraft: null,
    ListDelayCause: null,
    ListDelayCode: null,
    start_location: null,
    end_location: null,
    start_engine: "",
    texi_time: "",
    up_time: "",
    start_fuel: "",
    stop_engine: "",
    down_time: "",
    end_fuel: "",
    time: "",
    fuel: "",
    idflight: "",
    idflightlog: "",
    userdata: "",
    delaycause: "",
    delaycode: "",

    delay_remark: "",
    available_payload: "",
    actual_payload: "",
    actual_pax: "",

    flight_time: "",
    flight_time_start: "",
    flight_time_stop: "",

    readonly_flight: false,
    readonly_pilot: false,
    readonly_eng: false,
    readonly_shore: false,
    readonly_base: false,
    readonly_financial: false,
    readonly_management: true,

    //Add
    dialogSave: false,
    dialogConfirm: false,
    dialogSaveApprove: false,
    dialogConfirmApprove: false,
    start_engine_minute: "",
    taxi_minute: "",
    down_time_minute: "",
    fuel_consumption: "",
    air_fee: "",
    delayremark: "",

    has_taxi_time: false,
    has_stop_engine: false,

    shutdown: null,
    itemShutdown: [],

    //Add 20220323
    has_texi_time: false,
    //Add 20220324
    delayTime: 0,
  }),
  props: ["mode"],
  components: {
    Skytrac,
  },
  async mounted() {
    // this.userdata = JSON.parse(localStorage.getItem("user"));
    this.userdata = await this.$CurrentUser.GetCurrentUser();
    this.idflight = await this.$route.params.id;
    await this.enable_disable();

    await this.RenderUI();
  },
  filters: {
    setzoro: function (value) {
      return value === null ? 0 : value;
    },
  },
  computed: {
    formTitle() {
      return this.modeEvent === "create" ? "Add" : "Edit";
    },
  },
  methods: {
    async RenderUI() {
      this.itemShutdown.push({ sd_id: 0, sd_name: "-" });
      this.itemShutdown.push({ sd_id: 1, sd_name: "S/D Refuel" });
      this.itemShutdown.push({ sd_id: 2, sd_name: "S/D Standby" });
      this.itemShutdown.push({ sd_id: 3, sd_name: "S/D for Helideck Inspec" });

      await this.RenderDataFlightFromDB();
      await this.RenderDataAirport();
      await this.RenderDataDelayCause();
      await this.RenderDataDelayCode();

      await this.RenderDataFlightLog();
      await this.countleg();
      await this.Delay();
    },
    async enable_disable() {
      if (this.userdata.r_id == 4) {
        this.readonly_flight = true;
      } else if (this.userdata.r_id == 9) {
        this.readonly_eng = true;
      } else if (this.userdata.r_id == 12) {
        this.readonly_pilot = true;
      } else if (this.userdata.r_id == 2) {
        this.readonly_shore = true;
      } else if (this.userdata.r_id == 13) {
        this.readonly_base = true;
      } else if (this.userdata.r_id == 14) {
        this.readonly_financial = true;
      } else if (this.userdata.r_id == 15) {
        this.readonly_management = true;
      }
    },
    async RenderDataFlightFromDB() {
      try {
        var querydata = {};
        querydata.f_id = this.idflight;

        var res = await feathersClientUOA
          .service("viewflight")
          .find({ query: querydata });
        this.DataFromDB = res.data[0];
        //alert(this.DataFromDB.f_base_approve)

        // if(this.DataFromDB.f_departure_actual_date != null){
        //   this.DataFromDB.ap_depart_date = this.DataFromDB.f_departure_actual_date.substring(0, 10) + " " + this.DataFromDB.f_departure_actual_date.substring(11, 16);
        // }else{
        //   this.DataFromDB.ap_depart_date = this.DataFromDB.ap_depart_date.substring(0, 10) + " " + this.DataFromDB.ap_depart_date.substring(11, 16);
        // }
        this.DataFromDB.ap_depart_date =
          this.DataFromDB.ap_depart_date.substring(0, 10) +
          " " +
          this.DataFromDB.ap_depart_date.substring(11, 16);

        // if(this.DataFromDB.f_arrival_actual_date != null){
        //   this.DataFromDB.ap_dest_date = this.DataFromDB.f_arrival_actual_date.substring(0, 10) + " " + this.DataFromDB.f_arrival_actual_date.substring(11, 16);
        // }else{
        //   this.DataFromDB.ap_dest_date = this.DataFromDB.ap_dest_date.substring(0, 10) + " " + this.DataFromDB.ap_dest_date.substring(11, 16);
        // }
        this.DataFromDB.ap_dest_date =
          this.DataFromDB.ap_dest_date.substring(0, 10) +
          " " +
          this.DataFromDB.ap_dest_date.substring(11, 16);
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async Delay() {
      var querydatadelay = {};
      querydatadelay.f_id = this.idflight;
      var res = await feathersClientUOA
        .service("flightdelay")
        .find({ query: querydatadelay });
      if (res.total > 0) {
        this.DataDelayFromDB = res.data[0];
        this.delayTime = this.DataDelayFromDB.fd_time;
        this.delaycause = this.DataDelayFromDB.d_id;
        this.delaycode = this.DataDelayFromDB.dc_id;
        this.delayremark = this.DataDelayFromDB.fd_remark;
      }
    },
    async RenderDataAirport() {
      try {
        var queryAirport = {};
        queryAirport.retire = 0;
        var res = await feathersClientUOA
          .service("airports")
          .find({ query: queryAirport });
        this.ListAircraft = res.data;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async RenderDataDelayCause() {
      try {
        var res = await feathersClientUOA.service("delay").find({});
        this.ListDelayCause = res.data;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async RenderDataDelayCode() {
      try {
        var res = await feathersClientUOA.service("delaycode").find({});
        this.ListDelayCode = res.data;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async RenderDataFlightLog() {
      try {
        this.ArrLegs = [];
        var querydata = {};
        querydata.f_id = this.idflight;
        querydata.retire = 0;
        var res = await feathersClientUOA
          .service("flightlogdetail")
          .find({ query: querydata });
        this.ArrDataFlightLogFromDB = res.data;
        var i = 0;
        this.ArrDataFlightLogFromDB.forEach((element) => {
          this.RenderDataWaypoint(element.fld_id, i);
          this.ArrWaypoints = [];
          var datainsert = {};
          datainsert["fld_id"] = element.fld_id;
          datainsert["start_location"] = element.fld_start_location;
          datainsert["end_location"] = element.fld_end_location;

          //Start
          if (element.fld_start_engine != null) {
            datainsert["start_engine"] = element.fld_start_engine.substr(11, 8);
          }
          if (element.fld_has_texi_time) {
            datainsert["has_texi_time"] = true;
          } else {
            datainsert["has_texi_time"] = false;
          }
          if (element.fld_texi_time != null) {
            datainsert["texi_time"] = element.fld_texi_time.substr(11, 8);
          }
          if (element.fld_up_time != null) {
            datainsert["up_time"] = element.fld_up_time.substr(11, 8);
          }

          //ปิด start_fuel 20220323
          //datainsert["start_fuel"] = element.fld_start_fuel;

          //Stop
          if (element.fld_down_time != null) {
            datainsert["down_time"] = element.fld_down_time.substr(11, 8);
          }
          if (element.fld_has_stop_engine) {
            datainsert["has_stop_engine"] = true;
          } else {
            datainsert["has_stop_engine"] = false;
          }
          if (element.fld_stop_engine != null) {
            datainsert["stop_engine"] = element.fld_stop_engine.substr(11, 8);
          }

          //ปิด end_fuel 20220323
          //datainsert["end_fuel"] = element.fld_end_fuel;

          //Add 20210331
          //
          if (element.fld_shutdown != null) {
            datainsert["shutdown"] = this.itemShutdown[element.fld_shutdown];
          } else {
            datainsert["shutdown"] = this.itemShutdown[0];
          }

          //Add
          datainsert["delay_remark"] = element.fld_remark;
          datainsert["available_payload"] = element.fld_available_payload;
          datainsert["actual_payload"] = element.fld_actual_payload;
          datainsert["actual_pax"] = element.fld_actual_pax;
          datainsert["flight_time"] = element.fld_flight_time;
          datainsert["air_fee"] = element.fld_air_fee;
          datainsert["start_engine_minute"] = element.fld_start_engine_minute;
          datainsert["taxi_minute"] = element.fld_texi_minute;
          datainsert["down_time_minute"] = element.fld_down_time_minute;

          //Add 20200223
          datainsert["order"] = element.fld_order;

          if (element.fld_flight_time_start != null) {
            datainsert["flight_time_start"] =
              element.fld_flight_time_start.substr(11, 8);
          }
          if (element.fld_flight_time_stop != null) {
            datainsert["flight_time_stop"] =
              element.fld_flight_time_stop.substr(11, 8);
          }

          //Edit 20220323
          // datainsert["fuel_consumption"] = this.CalFuelConsumption(element.fld_start_fuel ,element.fld_end_fuel);
          datainsert["fuel_consumption"] = element.fld_sum_fuel;

          //Add 20220323
          datainsert["mileage"] = element.fld_mileage;
          //Add 20220425
          if (element.fld_shutdown > 1) {
            datainsert["standby_time"] = element.fld_standby_time;
          }

          datainsert["ArrWaypoints"] = this.ArrWaypoints;

          this.ArrLegs.push(datainsert);
          i++;
        });

        //Orderby
        this.ASCOrderbyOrder();
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },

    ASCOrderbyOrder() {
      this.ArrLegs.sort(function (a, b) {
        return a.order - b.order;
      });
    },

    async RenderDataWaypoint(fld_id, index) {
      try {
        var querydata = {};
        querydata.f_id = this.idflight;
        querydata.fld_id = fld_id;
        querydata.retire = 0;
        var res = await feathersClientUOA
          .service("waypoint")
          .find({ query: querydata });
        this.ArrWaypointsFromDB = res.data;
        this.ArrWaypointsFromDB.forEach((waypoint) => {
          var datainsertwaypoint = {};
          datainsertwaypoint["w_id"] = waypoint.w_id;
          // datainsertwaypoint["time"] = waypoint.w_time;
          //Edit
          if (waypoint.w_time != null) {
            datainsertwaypoint["time"] = waypoint.w_time.substr(11, 8);
          }
          datainsertwaypoint["fuel"] = waypoint.w_fuel;
          this.ArrLegs[index].ArrWaypoints.push(datainsertwaypoint);
        });
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async countleg() {
      try {
        var q = {};
        q.f_id = this.idflight;
        // q.retire = 0;

        var res = await feathersClientUOA
          .service("viewcountleg")
          .find({ query: q });

        var viewcountleg = res.data;
        this.ArrlistAddLegs = [];
        viewcountleg.forEach((element) => {
          var activity1Str = "";
          var activity2Str = "";
          var activity3Str = "";
          var activity4Str = "";
          if (element.l_activity1 === "1") {
            activity1Str = "S/D Refuel";
          } else {
            activity1Str = null;
          }
          if (element.l_activity2 === "1") {
            activity2Str = "S/D Refuel and HLO familiarize";
          } else {
            activity2Str = null;
          }
          if (element.l_activity3 === "1") {
            activity3Str = "S/D FOR JET A1 INSPECTION";
          } else {
            activity3Str = null;
          }
          if (element.l_activity4 === "1") {
            activity4Str = "S/D for Helideck Inspec";
          } else {
            activity4Str = null;
          }
          var newData = {
            f_id: element.f_id,
            ap_id: element.ap_id,
            activity1: activity1Str,
            activity2: activity2Str,
            activity3: activity3Str,
            activity4: activity4Str,
            ap_name: element.ap_name,
            l_ob: element.l_ob,
            l_ib: element.l_ib,
            ap_color: element.ap_color,
            ap_image_base64: element.ap_image_base64,
          };
          this.ArrlistAddLegs.push(newData);
        });
      } catch (error) {
        console.log(error);
      }
    },
    async AddWayPoint(index) {
      var datainsert = {};
      this.time = "";
      this.fuel = "";
      datainsert["w_id"] = null;
      datainsert["time"] = this.time;
      datainsert["fuel"] = this.fuel;
      this.ArrLegs[index].ArrWaypoints.push(datainsert);
    },
    async DeleteWayPoint(itemArrWaypoints, indexleg) {
      const index =
        this.ArrLegs[indexleg].ArrWaypoints.indexOf(itemArrWaypoints);
      if (index > -1) {
        this.ArrLegs[indexleg].ArrWaypoints.splice(index, 1);
        console.length(JSON.stringify(this.ArrLegs));
      }
    },
    async AddLeg() {
      this.start_location = "";
      this.end_location = "";
      this.start_engine = "";
      this.texi_time = "";
      this.up_time = "";
      //ปิด start_fuel 20220323
      //this.start_fuel = "";
      this.stop_engine = "";
      this.down_time = "";
      //ปิด end_fuel 20220323
      //this.end_fuel = "";
      this.shutdown = "";
      this.ArrWaypoints = [];
      var datainsert = {};
      this.shutdown = "";

      //Add 20220323
      this.has_texi_time = false;
      this.has_stop_engine = false;

      datainsert["fld_id"] = null;
      datainsert["start_location"] = this.start_location;
      datainsert["end_location"] = this.end_location;

      //Start
      datainsert["start_engine"] = this.start_engine;
      datainsert["has_texi_time"] = this.has_texi_time;
      datainsert["texi_time"] = this.texi_time;
      datainsert["up_time"] = this.up_time;
      //ปิด start_fuel 20220323
      //datainsert["start_fuel"] = this.start_fuel;

      //Stop
      datainsert["down_time"] = this.down_time;
      datainsert["has_stop_engine"] = this.has_stop_engine;
      datainsert["stop_engine"] = this.stop_engine;
      //ปิด end_fuel 20220323
      //datainsert["end_fuel"] = this.end_fuel;
      datainsert["shutdown"] = this.shutdown;

      //Add
      datainsert["delay_remark"] = this.flight_time;
      datainsert["flight_time_start"] = this.flight_time_start;
      datainsert["flight_time_stop"] = this.flight_time_stop;
      datainsert["flight_time"] = this.flight_time;

      datainsert["start_engine_minute"] = this.start_engine_minute;
      datainsert["texi_minute"] = this.texi_minute;
      datainsert["down_time_minute"] = this.down_time_minute;
      datainsert["fuel_consumption"] = this.fuel_consumption;

      datainsert["ArrWaypoints"] = this.ArrWaypoints;
      this.ArrLegs.push(datainsert);
    },
    async DeleteLeg(itemPass) {
      const index = this.ArrLegs.indexOf(itemPass);
      if (index > -1) {
        this.ArrLegs.splice(index, 1);
      }
    },
    async SaveFlightLog() {
      await this.SaveDelay();

      var querydata = {};
      querydata.f_id = this.idflight;

      var res = await feathersClientUOA
        .service("flightlogdetail")
        .find({ query: querydata });
      var dataUpdate = {
        retire: 1,
      };

      if (res.total > 0) {
        res.data.forEach(async (element) => {
          //Edit มันซ้ำซ้อน ความจริง update flightlogdetail retire == 1
          await feathersClientUOA
            .service("flightlogdetail")
            .patch(element.fld_id, dataUpdate);
        });
      }

      let i = 0;
      //Add async
      if (this.ArrLegs.length > 0) {
        this.ArrLegs.forEach(async (elementUpdate) => {
          let start_engine_minute = 0;
          let taxi_minute = 0;

          let blocktime =
            Math.floor(
              Math.abs(
                new Date("2020-06-01 " + elementUpdate.start_engine) -
                new Date("2020-06-01 " + elementUpdate.stop_engine)
              ) / 1000
            ) / 60;

          //if (elementUpdate.texi_time == "") {
          //Edit 20220323
          if (!elementUpdate.has_texi_time) {
            //start_engine_minute
            start_engine_minute = await this.CalStartEngineMinuteCaseNoTaxi(
              elementUpdate.start_engine,
              elementUpdate.up_time
            );
            taxi_minute = 0;
          } else {
            //start_engine_minute
            start_engine_minute = await this.CalStartEngineMinuteCaseWithTaxi(
              elementUpdate.start_engine,
              elementUpdate.texi_time
            );

            //taxi_minute
            var unixtimeUpTime = getTime(
              new Date("2020-12-16 " + elementUpdate.up_time)
            );
            var unixtimeTaxiTime2 = getTime(
              new Date("2020-12-16 " + elementUpdate.texi_time)
            );
            if (unixtimeUpTime < unixtimeTaxiTime2) {
              unixtimeUpTime = unixtimeUpTime + 24 * 60 * 60 * 1000;
            }
            taxi_minute = (unixtimeUpTime - unixtimeTaxiTime2) / 1000 / 60;
          }

          //down_time_minute
          var unixtimeDownTime = getTime(
            new Date("2020-12-16 " + elementUpdate.down_time)
          );
          var unixtimeStopEngine = getTime(
            new Date("2020-12-16 " + elementUpdate.stop_engine)
          );
          if (unixtimeStopEngine < unixtimeDownTime) {
            unixtimeStopEngine = unixtimeStopEngine + 24 * 60 * 60 * 1000;
          }
          let down_time_minute =
            (unixtimeStopEngine - unixtimeDownTime) / 1000 / 60;

          //Edit 20220323
          //Fuel
          // let sum_fuel = elementUpdate.start_fuel - elementUpdate.end_fuel;
          let sum_fuel = elementUpdate.fuel_consumption;

          let airtime =
            Math.floor(
              Math.abs(
                new Date("2020-06-01 " + elementUpdate.up_time) -
                new Date("2020-06-01 " + elementUpdate.down_time)
              ) / 1000
            ) / 60;
          let air_free = 0;
          if (
            elementUpdate.start_location == 1 ||
            elementUpdate.end_location == 1
          ) {
            air_free = 1;
          }

          //Update
          if (elementUpdate.fld_id != null) {
            if (!elementUpdate.has_texi_time) {
              elementUpdate.texi_time = elementUpdate.start_engine;
            }
            if (!elementUpdate.has_stop_engine) {
              elementUpdate.stop_engine = elementUpdate.down_time;
            }
            //Add 20220425
            if (elementUpdate.shutdown.sd_id < 2) {
              //0 => "-" or 1 => "S/D Refuel"
              elementUpdate.standby_time = null;
            }

            let dataUpdate = {
              fld_order: (i = i + 1),

              fld_start_location: elementUpdate.start_location.ap_id,
              fld_end_location: elementUpdate.end_location.ap_id,

              //Start
              fld_start_engine: elementUpdate.start_engine,
              fld_start_engine_minute: start_engine_minute,
              fld_has_texi_time: elementUpdate.has_texi_time,
              fld_texi_time: elementUpdate.texi_time,
              fld_texi_minute: taxi_minute,
              fld_up_time: elementUpdate.up_time,
              //ปิด start_fuel 20220323
              //fld_start_fuel: elementUpdate.start_fuel,

              fld_sum_fuel: sum_fuel,

              //End
              fld_down_time: elementUpdate.down_time,
              fld_down_time_minute: down_time_minute,
              fld_has_stop_engine: elementUpdate.has_stop_engine,
              fld_stop_engine: elementUpdate.stop_engine,
              //ปิด end_fuel 20220323
              //fld_end_fuel: elementUpdate.end_fuel,
              fld_shutdown: elementUpdate.shutdown.sd_id,

              airtime: airtime,
              fld_air_fee: air_free,
              blocktime: blocktime,
              retire: 0,

              //Add
              fld_remark: elementUpdate.delay_remark,
              fld_available_payload: elementUpdate.available_payload,
              fld_actual_payload: elementUpdate.actual_payload,
              fld_actual_pax: elementUpdate.actual_pax,
              fld_flight_time: elementUpdate.flight_time,
              fld_flight_time_start: elementUpdate.flight_time_start,
              fld_flight_time_stop: elementUpdate.flight_time_stop,

              //Add 20220322 mileage
              fld_mileage: elementUpdate.mileage,
              //Add 20220425 standby_time
              fld_standby_time: elementUpdate.standby_time,
            };
            //Add await
            await this.UpdateFlightLog(
              elementUpdate.fld_id,
              dataUpdate,
              elementUpdate.ArrWaypoints
            );
          } else if (elementUpdate.fld_id == null) {
            //Add 20220323 if if
            if (!elementUpdate.has_texi_time) {
              elementUpdate.texi_time = elementUpdate.start_engine;
            }
            if (!elementUpdate.has_stop_engine) {
              elementUpdate.stop_engine = elementUpdate.down_time;

              //Add if 20220323
              var unixtimeDownTime2 = getTime(
                new Date("2020-12-16 " + elementUpdate.down_time)
              );
              var unixtimeStopEngine2 = getTime(
                new Date("2020-12-16 " + elementUpdate.stop_engine)
              );
              if (unixtimeStopEngine2 < unixtimeDownTime2) {
                unixtimeStopEngine2 = unixtimeStopEngine2 + 24 * 60 * 60 * 1000;
              }
              down_time_minute =
                (unixtimeStopEngine2 - unixtimeDownTime2) / 1000 / 60;
            }
            //Add 20220425
            if (elementUpdate.shutdown.sd_id < 2) {
              //0 => "-" or 1 => "S/D Refuel"
              elementUpdate.standby_time = null;
            }

            var DataAdd = {
              f_id: this.idflight,
              fld_order: (i = i + 1),
              fld_start_location: elementUpdate.start_location.ap_id,
              fld_start_engine: elementUpdate.start_engine,
              //Add 20220323
              fld_has_texi_time: elementUpdate.has_texi_time,

              fld_texi_time: elementUpdate.texi_time,

              fld_texi_minute: taxi_minute,
              fld_start_engine_minute: start_engine_minute,
              fld_up_time: elementUpdate.up_time,
              //ปิด start_fuel 20220323
              //fld_start_fuel: elementUpdate.start_fuel,

              fld_sum_fuel: sum_fuel,

              //End
              fld_end_location: elementUpdate.end_location.ap_id,
              //Add 20220323
              fld_has_stop_engine: elementUpdate.has_stop_engine,

              fld_stop_engine: elementUpdate.stop_engine,
              fld_down_time: elementUpdate.down_time,
              fld_down_time_minute: down_time_minute,
              //ปิด end_fuel 20220323
              //fld_end_fuel: elementUpdate.end_fuel,
              fld_shutdown: elementUpdate.shutdown.sd_id,

              airtime: airtime,
              fld_air_fee: air_free,
              blocktime: blocktime,

              //Add
              fld_remark: elementUpdate.delay_remark,
              fld_available_payload: elementUpdate.available_payload,
              fld_actual_payload: elementUpdate.actual_payload,
              fld_actual_pax: elementUpdate.actual_pax,
              fld_flight_time: elementUpdate.flight_time,
              fld_flight_time_start: elementUpdate.flight_time_start,
              fld_flight_time_stop: elementUpdate.flight_time_stop,

              //Add 20220322 mileage
              fld_mileage: elementUpdate.mileage,
              //Add 20220425 standby_time
              fld_standby_time: elementUpdate.standby_time,
            };
            //Add await
            await this.InsertFlightLog(DataAdd, elementUpdate.ArrWaypoints);
          }
        });
      }

      this.dialogSave = false;
      this.dialogConfirm = true;
      //Add ย้ายมานี่ ล่าสุด delete
      //this.GotoFlightlog();
    },
    async SaveDelay() {
      // alert("this.delaycause.d_id : " + this.delaycause);
      // alert("this.delaycode.dc_id : " + this.delaycode);

      if (true) {
        var querydata = {};
        querydata.f_id = this.idflight;
        var res = await feathersClientUOA
          .service("flightdelay")
          .find({ query: querydata });
        //ปิด 20220324
        // let sumtimedelay =
        //   Math.floor(
        //     Math.abs(
        //       new Date(this.DataFromDB.ap_depart_date) -
        //         new Date(this.DataFromDB.f_actual_engine_start)
        //     ) / 1000
        //   ) / 60;

        var delaycode = null;
        if (this.delaycode != null) {
          delaycode = this.delaycode.dc_id;
        }
        var DataDelay = {
          f_id: this.idflight,
          d_id: this.delaycause.d_id,
          dc_id: delaycode,
          //Edit 20220324
          // fd_time: sumtimedelay,
          fd_time: this.delayTime,
          fd_remark: this.delayremark,
        };

        if (res.total > 0) {
          this.UpdateDelay(res.data[0].fd_id, DataDelay);
        } else {
          this.InsertDelay(DataDelay);
        }
      }
    },
    async InsertFlightLog(DataAdd, ArrWaypoints) {
      try {
        var res = await feathersClientUOA
          .service("flightlogdetail")
          .create(DataAdd);
        this.idflightlog = res.fld_id;

        var j = 0;

        ArrWaypoints.forEach(async (waypoint) => {
          j++;

          var DataWaypoint = {
            fld_id: this.idflightlog,
            w_order: j,
            f_id: this.idflight,
            w_time: waypoint.time,
            w_fuel: waypoint.fuel,
          };
          await this.InsertWaypoint(DataWaypoint);
        });
        //ย้ายไปตรงคำสั่งหลัก
        // this.GotoFlightlog();
      } catch (error) {
        console.log(error);
      }
    },
    async InsertWaypoint(DataWaypoint) {
      try {
        var res = await feathersClientUOA
          .service("waypoint")
          .create(DataWaypoint);
      } catch (error) {
        console.log(error);
      }
    },
    async UpdateFlightLog(Id, dataUpdate, ArrWaypoints) {
      try {
        var res = await feathersClientUOA
          .service("flightlogdetail")
          .patch(Id, dataUpdate);
        this.idflightlog = res.fld_id;

        var querydata = {};
        querydata.f_id = this.idflight;
        querydata.fld_id = res.fld_id;
        var reswaypoint = await feathersClientUOA
          .service("waypoint")
          .find({ query: querydata });
        var dataUpdatewaypoint = {
          retire: 1,
        };
        reswaypoint.data.forEach(async (element) => {
          this.UpdateWaypoint(element.w_id, dataUpdatewaypoint);
        });

        var j = 0;
        ArrWaypoints.forEach(async (waypoint) => {
          j++;
          if (waypoint.w_id != null) {
            var DataWaypoint = {
              w_order: j,
              w_time: waypoint.time,
              w_fuel: waypoint.fuel,
              retire: 0,
            };
            await this.UpdateWaypoint(waypoint.w_id, DataWaypoint);
          } else if (waypoint.w_id == null) {
            var DataWaypoint = {
              // fld_id: this.idflightlog,
              //Edit
              fld_id: Id,
              w_order: j,
              f_id: this.idflight,
              w_time: waypoint.time,
              w_fuel: waypoint.fuel,
            };

            await this.InsertWaypoint(DataWaypoint);
          }
        });
        //ย้ายไปตรงคำสั่งหลัก
        // this.GotoFlightlog();
      } catch (error) {
        console.log(error);
      }
    },
    async UpdateWaypoint(Id, dataUpdate) {
      // alert("UpdateWaypoint " + Id + " dataUpdate" + JSON.stringify(dataUpdate))
      try {
        await feathersClientUOA.service("waypoint").patch(Id, dataUpdate);
      } catch (error) {
        console.log(error);
      }
    },
    async Approve() {
      var dateNow = new Date();

      this.ArrLegs.forEach(async (element) => {
        var DataApprove = {
          f_id: this.idflight,
          c_id: this.DataFromDB.c_id,
          c_name: this.DataFromDB.c_name,
          fl_approve: 1,
          fl_approve_by: this.userdata.FullName,
          fl_approve_time: dateNow,
        };
        await this.InsertApprove(DataApprove);
      });

      //Add Update to tb flight
      var dataEdit = {
        f_base_approve: true,
        f_base_approve_by: this.userdata.FullName,
        f_base_approve_time: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),
        mb: this.userdata.FullName,
        md: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),
      };

      var res = await feathersClientUOA
        .service("flight")
        .patch(this.idflight, dataEdit);

      this.dialogSaveApprove = false;
      this.dialogConfirmApprove = true;
      // this.GotoFlightlog();
    },
    async InsertApprove(DataApprove) {
      try {
        await feathersClientUOA.service("flightlog").create(DataApprove);
      } catch (error) {
        console.log(error);
      }
    },
    async InsertDelay(DataDelay) {
      try {
        await feathersClientUOA.service("flightdelay").create(DataDelay);
      } catch (error) {
        console.log(error);
      }
    },
    async UpdateDelay(Id, DataDelay) {
      try {
        await feathersClientUOA.service("flightdelay").patch(Id, DataDelay);
      } catch (error) {
        console.log(error);
      }
    },
    async GotoFlightlog() {
      try {
        this.$router.push({ path: "/flightlog" });
      } catch (error) {
        console.log(error);
      }
    },
    async SaveCompleted() {
      this.dialogConfirm = false;
      await this.RenderUI();
    },
    async GotoSeatLayout() {
      try {
        this.$router.push({
          name: "SeatLayout",
          query: { mode: "add", idflight: this.idflight },
        });
      } catch (error) {
        console.log(error);
      }
    },

    async GotoListFlightLogDetail() {
      this.$router.push("/flightlogdetail");
    },

    async ConvertTimeHHmmssToUnixTime(time) {
      return getTime(new Date("2020-12-16 " + time));
    },

    async CheckedHasTaxiTime(index) {
      var data = this.ArrLegs[index];
      if (data.has_texi_time) {
        this.ArrLegs[index].texi_time = this.ArrLegs[index].start_engine;
      } else {
        this.ArrLegs[index].texi_time = null;
      }
    },

    async CheckedHasStopEngine(index) {
      var data = this.ArrLegs[index];
      if (data.has_stop_engine == true) {
        this.ArrLegs[index].stop_engine = this.ArrLegs[index].down_time;
      } else {
        this.ArrLegs[index].stop_engine = null;
      }
    },

    async CalFlightTime(index) {
      var data = this.ArrLegs[index];

      var start = "";
      var stop = "";

      //Summary row 1
      //Edit 20220329
      // if (this.DataFromDB.FH_basis == "AirTime") {
      if (this.DataFromDB.FH_basis == "Airtime") {
        if (data.up_time != "" && data.down_time != "") {
          start = data.up_time;
          stop = data.down_time;
          this.flight_time_start = data.up_time;
          this.flight_time_stop = data.down_time;
        }
      }

      //Edit 20220329
      // if (this.DataFromDB.FH_basis == "BlockTime") {
      if (this.DataFromDB.FH_basis == "Blocktime") {
        if (data.start_engine != "" && data.stop_engine != "") {
          start = data.start_engine;
          stop = data.stop_engine;
          this.flight_time_start = data.start_engine;
          this.flight_time_stop = data.stop_engine;
        }
      }

      if (start != "" && stop != "") {
        var unixtimeStart = await this.ConvertTimeHHmmssToUnixTime(start);
        var unixtimeEnd = await this.ConvertTimeHHmmssToUnixTime(stop);

        if (unixtimeEnd < unixtimeStart) {
          unixtimeEnd = unixtimeEnd + 24 * 60 * 60 * 1000;
        }

        var flightTime = (unixtimeEnd - unixtimeStart) / 1000 / 60;

        this.ArrLegs[index].flight_time_start = start;
        this.ArrLegs[index].flight_time_stop = stop;
        this.ArrLegs[index].flight_time = flightTime;
      }

      //Summary row 2
      //Edit 20220323
      // if (data.texi_time == "") {
      if (!data.has_texi_time) {
        if (data.start_engine != "" && data.up_time != "") {
          var start_engine_minute = await this.CalStartEngineMinuteCaseNoTaxi(
            data.start_engine,
            data.up_time
          );
          this.ArrLegs[index].start_engine_minute = start_engine_minute;
          this.ArrLegs[index].taxi_minute = 0;
        }
      } else {
        //start_engine_minute & taxi_minute
        if (
          data.start_engine != "" &&
          data.texi_time != "" &&
          data.up_time != ""
        ) {
          var start_engine_minute = await this.CalStartEngineMinuteCaseWithTaxi(
            data.start_engine,
            data.texi_time
          );
          var result_taxi_minute = await this.CalTaxiMinute(
            data.up_time,
            data.texi_time
          );
          this.ArrLegs[index].start_engine_minute = start_engine_minute;
          this.ArrLegs[index].taxi_minute = result_taxi_minute;
        }
      }

      if (data.down_time != "" && data.stop_engine != "") {
        //down_time_minute
        this.ArrLegs[index].down_time_minute = await this.CalDownTimeMinute(
          data.down_time,
          data.stop_engine
        );
      }

      //ปิดคำนวณ fuel_consumption 20220323
      // if (data.start_fuel != "" && data.end_fuel != "") {
      //   //fuel_consumption
      //   let consumption = this.CalFuelConsumption(
      //     data.start_fuel,
      //     data.end_fuel
      //   );
      //   this.ArrLegs[index].fuel_consumption = consumption;
      // }
    },

    //start_engine_minute case no taxi
    async CalStartEngineMinuteCaseNoTaxi(start_engine, up_time) {
      var start_engine_minute = 0;
      var unixtimeStartEngine = await this.ConvertTimeHHmmssToUnixTime(
        start_engine
      );
      var unixtimeUpTime = await this.ConvertTimeHHmmssToUnixTime(up_time);
      if (unixtimeUpTime < unixtimeStartEngine) {
        unixtimeUpTime = unixtimeUpTime + 24 * 60 * 60 * 1000;
      }
      start_engine_minute = (unixtimeUpTime - unixtimeStartEngine) / 1000 / 60;
      return start_engine_minute;
    },

    async CalStartEngineMinuteCaseWithTaxi(start_engine, texi_time) {
      var start_engine_minute = 0;
      var unixtimeStartEngine = await this.ConvertTimeHHmmssToUnixTime(
        start_engine
      );
      var unixtimeTaxiTime1 = await this.ConvertTimeHHmmssToUnixTime(texi_time);
      if (unixtimeTaxiTime1 < unixtimeStartEngine) {
        unixtimeTaxiTime1 = unixtimeTaxiTime1 + 24 * 60 * 60 * 1000;
      }
      start_engine_minute =
        (unixtimeTaxiTime1 - unixtimeStartEngine) / 1000 / 60;
      return start_engine_minute;
    },

    async CalDownTimeMinute(down_time, stop_engine) {
      let down_time_minute = 0;
      var unixtimeDownTime = getTime(new Date("2020-12-16 " + down_time));
      var unixtimeStopEngine = getTime(new Date("2020-12-16 " + stop_engine));
      if (unixtimeStopEngine < unixtimeDownTime) {
        unixtimeStopEngine = unixtimeStopEngine + 24 * 60 * 60 * 1000;
      }
      down_time_minute = (unixtimeStopEngine - unixtimeDownTime) / 1000 / 60;
      return down_time_minute;
    },

    async CalTaxiMinute(up_time, texi_time) {
      let taxi_minute = 0;
      //Add if 20220323
      //if(this.has_texi_time){
      var unixtimeUpTime = getTime(new Date("2020-12-16 " + up_time));
      var unixtimeTaxiTime2 = getTime(new Date("2020-12-16 " + texi_time));
      if (unixtimeUpTime < unixtimeTaxiTime2) {
        unixtimeUpTime = unixtimeUpTime + 24 * 60 * 60 * 1000;
      }
      taxi_minute = (unixtimeUpTime - unixtimeTaxiTime2) / 1000 / 60;
      //}
      return taxi_minute;
    },

    CalFuelConsumption(start_fuel, end_fuel) {
      let fuel_consumption = start_fuel - end_fuel;
      return fuel_consumption;
    },
  },
};
</script>
<style>

</style>